/* eslint-disable @typescript-eslint/no-explicit-any */

import { ResolvableModelClass }    from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { IResolvableAttributes }   from '@mathquis/modelx-resolvables/lib/types/AbstractResolvableModel';
import { AbstractResolvableModel } from '@mathquis/modelx-resolvables';
import { ResolvableCollection }    from '@mathquis/modelx-resolvables';
import { IModelOptions }           from '@mathquis/modelx/lib/types/model';
import { ConnectorResult }         from '@mathquis/modelx';
import { SortWaySet }              from 'Collections/AbstractApiCollection';
import { ApiCollection }           from 'Collections/ApiCollection';
import { PagedCollection }         from 'Collections/PagedCollection';
import ModelNameImport             from 'components/ModelNameImport';
import { IModelNameProps }         from 'components/ModelName';
import ModelCache                  from 'helpers/ModelCache';
import ModelDictionary             from 'helpers/ModelDictionary';
import _get                        from 'lodash/get';
import { action }                  from 'mobx';
import { computed }                from 'mobx';
import { observable }              from 'mobx';
import { makeObservable }          from 'mobx';
import React                       from 'react';
import { getIdFromUrn }            from 'tools/UrnTools';
import browserHistory              from 'tools/browserHistory';
import AbstractApiStaticLabelModel from './AbstractApiStaticLabelModel';

export interface IApiResolvableAttributes extends IResolvableAttributes {
	'@id': string;
	'@type': string;
	'@urn': string;
}

export interface IRenderNameProps<T extends AbstractApiModel> extends Omit<IModelNameProps<T>, 'model'> {
}

AbstractResolvableModel.getDefaults().log = true;

type OptionRetry<T extends AbstractApiModel> = {
	interval?: number;
	replace?: boolean;
	timeout?: number;
	until?: (model: T) => boolean | Promise<boolean>;
}

type UrnData = { partition: string; resource: string; service: string; };

export type PageType = 'dashboard' | 'list';

export default abstract class AbstractApiModel extends AbstractApiStaticLabelModel {
	public _filters: ModelFiltersExtended<DefaultFilters> = {};
	public _sorts: ModelSortsExtended<DefaultSorts> = {};

	public static cacheDuration: number | boolean = false;

	public static serviceName = '';

	public get serviceUrn(): ServiceUrn {
		return `$:registry:service:${this.constructor['serviceName'] as ServiceName}`;
	}

	public connectorResultFetch?: ConnectorResult;

	public static get urnData(): UrnData {
		return (this.constructor as never)['urnData'];
	}

	public get urnData(): UrnData {
		return this.constructor['urnData'];
	}

	@observable
	private _fetchError: Error | null;

	constructor(attributes?: Record<string, unknown>, options?: IModelOptions) {
		super(attributes, options);

		this._fetchError = null;

		makeObservable(this);
	}

	public get id(): Exclude<id, undefined> {
		return this.get('id', 0);
	}

	@computed
	public get iri(): string {
		return this.get('@id', '');
	}

	@computed
	public get urn(): Urn {
		return this.get('@urn', '');
	}

	@computed
	public get createdByUrn(): string | null {
		return this.get('createdBy', '');
	}

	@computed
	public get createdById(): id {
		return parseInt(getIdFromUrn(this.createdByUrn || '')) || 0;
	}

	public clearCache() {
		ModelCache.removeCacheForModel(this);

		return this;
	}

	public fetch(options?: ApiConnectorOptions<this>) {
		return super.fetch(options);
	}

	public async fetchRetry(retryOptions: OptionRetry<this>, options?: ApiConnectorOptions<this>) {
		const retry: OptionRetry<this> = { interval: 2000, timeout: 30000, ...retryOptions };

		const model = this.clone();
		const isEnded = async (m: this) => retry.until ? retry.until(m) : false;
		const onSuccess = () => {
			this.pendingRequestCount--;
			this.set(model.attributes);
			this.setIsLoaded(true);
			return this;
		};

		this.pendingRequestCount++;
		await model.fetch(options);

		if (await isEnded(model)) {
			return onSuccess();
		}

		return new Promise<this>(resolve => {
			const interval = setInterval(async () => {
				if (!model.isLoading) {
					await model.clear().setId(this.id).fetch(options);

					if (await isEnded(model)) {
						clearInterval(interval);
						resolve(onSuccess());
					}
				}
			}, retry.interval);

			setTimeout(() => clearInterval(interval), retry.timeout);
		});
	}

	public async fetchWithFilters(filters: ModelFilters<this>, sortName?: ModelSortName<this>, way: SortWaySet = true) {
		try {
			this.pendingRequestCount--;
			const pagedCollection = new PagedCollection(this.modelClass as never);
			await pagedCollection.setItemsPerPage(1).setFilters(filters).setSort(sortName as never, way).list();
			const first = pagedCollection.first();

			if (first) {
				this.set(first.attributes);
				this.setIsLoaded(true);
			}

		} finally {
			this.pendingRequestCount++;
		}
	}

	public getId(propName?: Extract<keyof this, string>) {
		return propName ? (
			this.get(`${propName}.id`) || getIdFromUrn(this.get(`${propName}Urn`)) || _get(this, `${propName}.id`)
		) : this.id;
	}

	public getIri(propName?: Extract<keyof this, string>): string | undefined {
		return propName ? (this.get(`${propName}.@id`) || this[propName]['iri'] || undefined) : this.iri;
	}

	// TO IMPLEMENT IF NECESSARY
	public getResolvableModelClass(propName: string, attributeName: string, attribute: any): ResolvableModelClass<AbstractResolvableModel> | undefined | null {
		if (typeof attribute === 'string') { // URN ?
			const urnModelClass = ModelDictionary.get(attribute);
			return urnModelClass as unknown as ResolvableModelClass<AbstractResolvableModel> || undefined;
		} else if (attribute && attribute['@urn']) {
			const urnModelClass = ModelDictionary.get(attribute['@urn']);
			if (urnModelClass) {
				return urnModelClass as unknown as ResolvableModelClass<AbstractResolvableModel> || undefined;
			}
		}

		return null;
	}

	public getUrn(propName?: Extract<keyof this, string>) {
		return propName ? (
			this.get(`${propName}.@urn`)
			|| this.get(`${propName}Urn.@urn`)
			|| (typeof this.get(`${propName}Urn`) === 'string' ? this.get(`${propName}Urn`) : false)
			|| this[propName]['urn']
		) : this.urn;
	}

	public goTo(type: PageType = 'dashboard') {
		const path = this.pathTo(type);
		browserHistory.push(path);
	}

	public patch(attributes: Record<string, unknown> = {}, options: ApiConnectorOptions<this> = {}) {
		return this.save({ ...options, patchAttributes: attributes });
	}

	public patchFormData(attributes: Record<string, unknown> = {}, options: ApiConnectorOptions<this> = {}) {
		const formData = new FormData();

		Object.keys(attributes).forEach((key) => {
			const value = attributes[key];

			if (Array.isArray(value)) {
				(value as []).forEach((subValue, index) => {
					formData.append(`${key}[${index}]`, subValue);
				});
			} else {
				formData.append(key, value as (string | Blob));
			}
		});

		return this.save({
			...options,
			headers: {
				// 'Content-Type': 'multipart/form-data',
				...options.headers,
			},
			patchAttributes: formData,
		});
	}

	public pathTo(type: PageType = 'dashboard') {
		switch (type) {
			case 'dashboard':
				return this.path;
			case 'list':
				return this.constructor['pathToList'];
			default:
				return '';
		}
	}

	public renderName(props?: IRenderNameProps<this>) {
		return React.createElement(ModelNameImport, { ...props, model: this });
	}

	public setId(id: id): this {
		super.setId(id);

		return this;
	}

	@action
	public setIsLoaded(value = true) {
		this.isLoaded = value;
	}

	public get fetchError(): Error | null {
		return this._fetchError;
	}

	public get isFirstLoading(): boolean {
		return !this.isLoaded && this.isLoading;
	}

	public get isLoadedOrLoading(): boolean {
		return this.isLoaded || this.isLoading;
	}

	public get isRefreshing(): boolean {
		return this.isLoaded && this.isLoading;
	}

	// TO IMPLEMENT
	static getResolvableCollection(): ResolvableCollection<AbstractResolvableModel> {
		return ApiCollection;
	}

	public static get pathToList() {
		return (new ApiCollection(this as never)).path.slice(0, -1);
	}

	public static goToList() {
		browserHistory.push(this.pathToList);
	}

	@action
	protected _setFetchError(err: Error | null) {
		this._fetchError = err;
	}

	// TO IMPLEMENT IF NECESSARY
	protected getAttributeFromModel(propName: string, attributeName: string, model: AbstractResolvableModel): any {
		if (model.get('@type') === 'urn') {
			return model.get('@urn');
		}
		return {
			'@id': model.get('@id'),
			'@type': model.get('@type'),
			'@urn': model.get('@urn'),
			id: model.id,
		};
	}

	// TO IMPLEMENT
	protected getResolvableAttributes(propName: string, attributeName: string, attribute: any):
		IApiResolvableAttributes | undefined {
		if (typeof attribute === 'string') { // URN ?
			const [, , , identifier] = attribute.split(':');
			return {
				'@id': attribute,
				'@type': 'urn',
				'@urn': attribute,
				id: parseInt(identifier),
			};
		}
		return attribute;
	}

	protected onDestroySuccess(result: ConnectorResult, options: ApiConnectorOptions<this>) {
		super.onDestroySuccess(result, options);

		if (this.collection as unknown instanceof PagedCollection) {
			const pagedCollection = this.collection as PagedCollection<never>;

			pagedCollection.setTotal(pagedCollection.total - 1);
		}
	}

	protected onFetchSuccess(result, options: ApiConnectorOptions<this>) {
		super.onFetchSuccess(result, options);

		this._setFetchError(null);

		this.connectorResultFetch = { ...result };
	}
}
